import * as React from "react";
import { graphql } from "gatsby";
import { Container } from "react-bootstrap";

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import Footer from "../parts/Footer";
import Header from "../parts/Header";
import bg from "../images/home-bg.webp";
import CustomHelmet from "../components/seo/CustomHelmet";

import loadable from '@loadable/component';

// markup
const IndexPage = ({ location, data }) => {
  const canonicalUrl = data.site.siteMetadata.siteUrl + location.pathname

  const { markdownRemark } = data;
  const { frontmatter } = markdownRemark;

  const Carousel = loadable(() => import("../components/Carousel"))

  return (
    <>
      <CustomHelmet title={"About"} canonicalUrl={canonicalUrl} />
      <Header activeKey="/" />
      <main style={{
        backgroundImage: `url(${bg})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        marginTop: '81px'
      }}>
        <div className="w-100 h-100" style={{
          background: "linear-gradient(180deg, rgba(29, 13, 1, 0.74) 0%, #121111 100%)",
          padding: '105px 0'
        }}>
          <Container style={{ maxWidth: "870px" }}>
            <div className="d-flex flex-column align-items-center text-center text-white">
              <h1 className="text-center mb-3">Welcome!</h1>

              <p dangerouslySetInnerHTML={{ __html: frontmatter.description }} />
              <p>
                {frontmatter.joinMessage}
              </p>
              <div className="w-100 py-4 px-4 my-3 rounded"
                style={{
                  background: "rgba(255, 255, 255, 0.93)",
                  boxShadow: "0px 12px 15px rgba(54, 24, 13, 0.15)",
                  backdropFilter: "blur(6px)",
                }}>
                <Carousel frontmatter={frontmatter} />
              </div>
            </div>
          </Container>
        </div>
      </main >
      <Footer />
    </>
  )
}

export const pageQuery = graphql`
{
  markdownRemark(frontmatter: {slug: {eq: "/content/about/about"}}) {
    html
    frontmatter {
      date(formatString: "MMM DD/YYYY")
      slug
      title
      description
      logos {
        img {
          childImageSharp {
            fluid(maxWidth: 800) {
              src
            }
          }
        }
        h
      }
      joinMessage
    }
  }
  site {
    siteMetadata {
      siteUrl
    }
  }
}
`

export default IndexPage
